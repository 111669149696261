<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-row class="top_l" :gutter="10">
                    <el-col :span="6" :xs="24"><el-input v-model="uuid" clearable
                            placeholder="请输入手机号"></el-input></el-col>
                    <el-col :span="2" :xs="24"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
                </el-row>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
                    style="width: 100%" border stripe>
                    <el-table-column prop="productId" label="productId"></el-table-column>
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="userId" label="用户id"></el-table-column>
                    <el-table-column prop="payDetailId" label="支付明细id"></el-table-column>
                    <el-table-column prop="exchangeCode" label="兑换码" min-width="180"></el-table-column>
                    <el-table-column label="状态" align="center">
                        <template v-slot="scope">
                            <span v-if="scope.row.status == -1">
                                失败
                            </span>
                            <span v-if="scope.row.status == 0">
                                初始
                            </span>
                            <span v-if="scope.row.status == 1">
                                已兑换
                            </span>
                            <span v-if="scope.row.status == 2">
                                兑换中
                            </span>
                            <span v-if="scope.row.status == 3">
                                已过期
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="exchangeTime" label="兑换时间" min-width="100"></el-table-column>
                    <el-table-column prop="validStartTime" label="有效期起始时间" min-width="100"></el-table-column>
                    <el-table-column prop="validEndTime" label="有效期结束时间" min-width="100"></el-table-column>
                    <el-table-column label="补发状态" align="center">
                        <template v-slot="scope">
                            <div v-if="scope.row.status == -1">
                                <span v-if="scope.row.reissueStatus == 1">
                                    已补发
                                </span>
                                <span v-else>
                                    未补发
                                </span>
                            </div>
                            <div v-else>--</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reissueSource" label="补发来源的兑换码"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" min-width="100"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间" min-width="100"></el-table-column>
                    <el-table-column label="操作" width="100" align="center">
                        <template v-slot="scope">
                            <el-button size="small" v-if="scope.row.status == -1 && scope.row.reissueStatus != 1"
                                type="primary" plain @click="reissue(scope.row)">补发</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'userCouponCode',
    data() {
        return {
            dialogAdd: false,
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            uuid: ''
        };

    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }

        this.productId = this.$TOOL.data.get('DATA_SELECTEED');
        // 获取列表
        this.userCouponCodeC();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval;
                // 获取列表
                this.userCouponCodeC();
            }
        }
    },
    methods: {
        // 获取列表
        userCouponCodeC() {
            this.serloading = true;
            this.$HTTP.post(
                'userCouponCode/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    uuid: this.uuid
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 搜索
        jouShare() {
            this.currentPage = 1;
            // 获取列表
            this.userCouponCodeC();
        },
        // 补发
        reissue(item) {
            this.$HTTP.post(
                'userCouponCode/reissue',
                {
                    productId: this.productId,
                    id: item.id,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取列表
                            this.userCouponCodeC();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取列表
            this.userCouponCodeC();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取列表
            this.userCouponCodeC();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
